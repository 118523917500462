<template>
  <div id="discography-container" class="p-10">
    
  </div>
</template>

<script>
export default {
  name: "DiscographyComponent"
}
</script>

<style scoped>

</style>