<template>
    <div class="" id="biography-container">
      <div class="flex flex-col w-4/12 mb-[2%]">
        <p class="item-left text-white self-end text-3xl">Welkom!</p>
        <div class="h-2 bg-red-800"></div>
      </div>
      <div class="flex flex-col text-clip overflow-hidden ">
        <div class="flex flex-col text-elipsis mt-[2%] pl-[20%]">
          <div class="flex text-xl text-white mb-3">Erik Geskus</div>
        </div>
        <div class="pl-[20%] pr-[20%] text-white opacity-50">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer tos It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
        </div>
      </div>
    </div>  
    <div class="pb-[10%]">
    </div>
  </template>

<script>
 export default {
  name: "BiographyComponent",
}
</script>

<style scoped>
</style>
