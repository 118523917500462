<template>
  <div class="app bg-black text-white">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import url("../src/assets/style.css");
</style>